import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import img0 from "../../assets/images/box-item/diddy1.jpg";
import img1 from "../../assets/images/box-item/diddy2.jpg";
import img2 from "../../assets/images/box-item/diddy3.jpg";
import img3 from "../../assets/images/box-item/diddy4.jpg";
import img4 from "../../assets/images/box-item/diddy7.jpg";
import img5 from "../../assets/images/box-item/diddy6.jpg";
import img6 from "../../assets/images/box-item/diddy8.jpg";
import img7 from "../../assets/images/box-item/diddy9.jpg";
import img8 from "../../assets/images/box-item/diddy10.jpg";
import img9 from "../../assets/images/box-item/diddy11.jpg";
import img10 from "../../assets/images/box-item/diddy12.jpg";
import img11 from "../../assets/images/box-item/diddy14.jpg";
import img12 from "../../assets/images/box-item/diddy13.jpg";
import img13 from "../../assets/images/box-item/diddy15.jpg";
import img14 from "../../assets/images/box-item/diddy16.jpg";
import img15 from "../../assets/images/box-item/diddy17.jpg";
import diddyz from "../../assets/images/avatar/diddy-big-removebg-preview.png";

const SliderStyle2 = () => {
  const subtitle = "$DIDDY";
  const title = "Come and party with $DIDDY";
  const description =
    "Did $diddy do it? come and find out join our TG and see what we are all about";

  return (
    <section className="flat-title-page home5">
      <div className="overlay"></div>
      <div className="themesflat-container">
        <div className="wrap-heading flat-slider d-flex align-items-center">
          <div className="col d-flex align-items-center diddy-hero">
            <img src={diddyz} height={700} />
            <div>
              <h4 className="mg-bt-11">
                <span className="fill">{subtitle}</span>
              </h4>
              <h1 className="heading">{title}</h1>
              <p className="sub-heading mg-t-7 mg-bt-39">{description}</p>
              <div className="flat-bt-slider style2 flex">
                <a
                  href="https://twitter.com/peediddyonsol"
                  className="sc-button header-slider style style-1 rocket fl-button pri-1"
                >
                  <span>Party with me</span>
                </a>
                <a
                  href="https://jup.ag/swap/DIDDY_F9HXut47NggXSDBj9Ubgrf2i6b7EVfUEQyGYZ7ZKrbJ9-SOL"
                  className="sc-button header-slider style style-1 bag fl-button pri-1"
                >
                  <span>Buy me</span>
                </a>
              </div>
            </div>
          </div>

          <div className="memes" style={{ display: "flex" }}>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              loop
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={2000}
            >
              <SwiperSlide>
                <img src={img0} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img4} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img5} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img9} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img6} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              loop
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={2100}
            >
              <SwiperSlide>
                <img src={img2} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img3} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img1} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img7} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img8} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
            </Swiper>
            <Swiper
              modules={[Autoplay]}
              direction={"vertical"}
              spaceBetween={10}
              slidesPerView={5}
              loop
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              speed={2200}
            >
              <SwiperSlide>
                <img src={img10} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img11} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img12} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img13} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={img14} alt="Axies" style={{ maxWidth: "255px" }} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SliderStyle2;
