import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CardModal from "../CardModal";

import img0 from "../../../assets/images/box-item/diddy1.jpg";
import img1 from "../../../assets/images/box-item/diddy2.jpg";
import img2 from "../../../assets/images/box-item/diddy3.jpg";
import img3 from "../../../assets/images/box-item/diddy4.jpg";
import img4 from "../../../assets/images/box-item/diddy7.jpg";
import img5 from "../../../assets/images/box-item/diddy6.jpg";
import img6 from "../../../assets/images/box-item/diddy8.jpg";
import img7 from "../../../assets/images/box-item/diddy9.jpg";
import img8 from "../../../assets/images/box-item/diddy10.jpg";
import img9 from "../../../assets/images/box-item/diddy11.jpg";
import img10 from "../../../assets/images/box-item/diddy12.jpg";
import img11 from "../../../assets/images/box-item/diddy14.jpg";
import img12 from "../../../assets/images/box-item/diddy13.jpg";
import img13 from "../../../assets/images/box-item/diddy15.jpg";
import img14 from "../../../assets/images/box-item/diddy16.jpg";
import img15 from "../../../assets/images/box-item/diddy17.jpg";

const TodayPicks = () => {
  const [dataTab] = useState([
    {
      id: 1,
      title: "All",
    },
  ]);
  const [dataPanel] = useState([
    {
      id: 1,
      dataContent: [
        {
          id: 1,
          img: img1,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 2,
          img: img2,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
          feature: "Coming Soon",
        },
        {
          id: 3,
          img: img3,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 4,
          img: img4,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 5,
          img: img5,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 6,
          img: img6,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 7,
          img: img7,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 8,
          img: img8,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 9,
          img: img9,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 10,
          img: img10,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 11,
          img: img11,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 12,
          img: img12,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 13,
          img: img13,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 14,
          img: img14,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 15,
          img: img15,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 16,
          img: img8,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
      ],
    },
    {
      id: 2,
      dataContent: [
        {
          id: 2,
          img: img2,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 3,
          img: img3,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 4,
          img: img4,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 5,
          img: img5,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
      ],
    },
    {
      id: 3,
      dataContent: [
        {
          id: 1,
          img: img1,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 3,
          img: img3,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 4,
          img: img4,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 5,
          img: img5,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
      ],
    },
    {
      id: 4,
      dataContent: [
        {
          id: 1,
          img: img1,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 2,
          img: img2,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 3,
          img: img3,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 5,
          img: img5,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 7,
          img: img7,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
      ],
    },
    {
      id: 5,
      dataContent: [
        {
          id: 2,
          img: img2,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 3,
          img: img3,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 4,
          img: img4,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
        {
          id: 6,
          img: img5,
          title: "The RenaiXance Rising the sun ",
          tags: "bsc",
          nameAuthor: "SalvadorDali",
          price: "4.89 ETH",
          priceChange: "$12.246",
          wishlist: "100",
          nameCollection: "Creative Art 3D",
        },
      ],
    },
  ]);

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <Fragment>
      <div className="tf-section sc-explore-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="flat-tabs explore-tab">
                <Tabs>
                  <TabList>
                    {dataTab.map((data) => (
                      <Tab key={data.id}>{data.title}</Tab>
                    ))}
                  </TabList>
                  {dataPanel.map((data) => (
                    <TabPanel key={data.id}>
                      {data.dataContent.slice(0, visible).map((item) => (
                        <div
                          key={item.id}
                          className={`sc-card-product explode style2 mg-bt`}
                        >
                          <div className="card-media">
                            <Link to="/item-details-01">
                              <img src={item.img} alt="Axies" />
                            </Link>
                            <div className="button-place-bid">
                              <Link
                                to="https://jup.ag/swap/SOL-F9HXut47NggXSDBj9Ubgrf2i6b7EVfUEQyGYZ7ZKrbJ9"
                                className="sc-button style-place-bid style bag fl-button pri-3"
                              >
                                <span>Get $DIDDY</span>
                              </Link>
                            </div>

                            <div className="coming-soon">{item.feature}</div>
                          </div>
                        </div>
                      ))}
                      {visible < data.dataContent.length && (
                        <div className="col-md-12 wrap-inner load-more text-center">
                          <Link
                            to="#"
                            id="load-more"
                            className="sc-button loadmore fl-button pri-3"
                            onClick={showMoreItems}
                          >
                            <span>Load More</span>
                          </Link>
                        </div>
                      )}
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </Fragment>
  );
};

export default TodayPicks;
