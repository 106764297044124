import React, { useEffect } from "react";
import HeaderStyle2 from "../components/header/HeaderStyle2";
import SliderStyle2 from "../components/slider/SliderStyle2";
import heroSliderData from "../assets/fake-data/data-slider";
import Create from "../components/layouts/home-2/Create";
import meek from "../assets/audio/backgroundmeek.mp3";
import ItemDetails02 from "./ItemDetails02";
import Footer from "../components/footer/Footer";

const Home05 = () => {
  useEffect(() => {
    const audio = new Audio(meek);
    audio.loop = true; // Set loop property to true
    audio.play();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []); //
  return (
    <div className="home-5">
      <HeaderStyle2 />
      <SliderStyle2 data={heroSliderData} />
      <ItemDetails02 />
      <Footer />
    </div>
  );
};

export default Home05;
