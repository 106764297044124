import React, { useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../assets/fake-data/data-live-auction";
import LiveAuction from "../components/layouts/LiveAuction";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/icon/Telegram_logo.svg.webp";
import img7 from "../assets/images/avatar/avt-2.jpg";
import imgdetail1 from "../assets/images/avatar/diddybg.jpg";

const ItemDetails02 = () => {
  const [dataHistory] = useState([
    {
      img: img1,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img2,
      name: "Mason Woodward",
      time: "at 06/10/2021, 3:20 AM",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img3,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img4,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img5,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
    {
      img: img6,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 ETH",
      priceChange: "$12.246",
    },
  ]);
  return (
    <div className="item-details">
      <div className="tf-section tf-item-details style-2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <img src={imgdetail1} alt="Axies" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="meta-item">
                    <div className="left">
                      <h2>GET $DIDDY</h2>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={img6} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Owned By</span>
                          <h6>
                            {" "}
                            <Link to="https://t.me/peediddysol">
                              Community
                            </Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={imgdetail1} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Created By</span>
                          <h6>
                            {" "}
                            <Link to="https://dexscreener.com/solana/d5twxj4zlvwnqudvxcjqdbsoim7woul2ccwrsuednqqt">
                              peediddy
                            </Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    Yo, you ready to dive into this Solana game, huh? Let me
                    drop some knowledge on you, 'cause apparently, I'm the go-to
                    guy for this.
                    <br></br>
                    First off, you gotta lock down a SOLANA WALLET. 'Cause
                    what's cooler than entrusting your digital cash to something
                    called Phantom or Solflare, right? Just slap that extension
                    on your browser or grab the app, and follow the flow. And
                    yo, keep that recovery phrase under lock and key - ain't
                    nobody touching your precious SOL!
                    <br></br>
                    Now, it's time to STACK SOME SOL. 'Cause what's a wallet
                    without some Solana to fill it up? Hit up one of those slick
                    exchanges like Binance or Coinbase, grab yourself some SOL,
                    then shoot it over to your fresh Solana wallet address. Easy
                    street, my friend.
                    <br></br>
                    Last but not least, it's all about SWAPPIN' ON Raydium.
                    'Cause why just HODL your SOL when you can move it around
                    like a boss? Slide on over to Raydium, link up your wallet,
                    set your swap amount, and give the green light. Just follow
                    the cues on your wallet interface to seal the deal, and
                    boom! You're a certified Solana player.
                    <br></br>
                    But listen up, folks - the $DIDDY fam got your back on this
                    journey. We ain't just sitting back while you navigate this
                    crypto landscape. Trust in Diddy, and we'll ride this Solana
                    rocket to the stars! Or, you know, to financial freedom.
                    Whichever comes first.
                  </p>
                  <div className="meta-item-details">
                    <div className="item-style-2 item-details">
                      <ul className="list-details">
                        <li>
                          <span>Supply : </span>
                          <h6>9,999,126.01</h6>{" "}
                        </li>
                        <li>
                          <span>Circulating supply : </span>
                          <h6>96.5%</h6>{" "}
                        </li>
                        <li>
                          <span>Ownership : </span>
                          <h6>Renounced</h6>{" "}
                        </li>
                        <li>
                          <span>Created at : </span>
                          <h6>29 March , 2024</h6>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="item-style-2">
                      <div className="item meta-price">
                        <span className="heading">Dead supply</span>
                        <div className="price">
                          <div className="price-box">
                            <h5>369 k</h5>
                            <span>= 3.5% of supply</span>
                          </div>
                        </div>
                      </div>
                      <div style={{ fontSize: "12px" }} className="item">
                        <span>
                          CA: F9HXut47NggXSDBj9Ubgrf2i6b7EVfUEQyGYZ7ZKrbJ9
                        </span>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="https://jup.ag/swap/SOL-F9HXut47NggXSDBj9Ubgrf2i6b7EVfUEQyGYZ7ZKrbJ9"
                    className="sc-button loadmore style bag fl-button pri-3"
                  >
                    <span>Buy $DIDDY</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails02;
