const menus = [
  {
    id: 1,
    name: "Memes",
    links: "/memes",
    link: "/memes",
  },
];

export default menus;
